<template>
<h1 class="text-center">Sidebars</h1>
</template>

<script>
export default {
  name: "Sidebars"
}
</script>

<style scoped>

</style>